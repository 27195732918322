<template>
  <v-chip
    class="mx-2 my-2 text-uppercase"
    label
    :class="getStatusColor(item)"
  >
    {{ getStatusLabel(item) }}
  </v-chip>
</template>

<script>
export default {
  name: 'StatusLabel',

  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getStatusLabel(item) {
      let type =  item['@type'] ?? ''
      let status = item.status ?? ''

      return this.$i18n.t('status.' + type.toLowerCase() + '.' + status.toLowerCase() + '.label')
    },
    getStatusColor(item) {
      let type =  item['@type'] ?? ''
      let status = item.status ?? ''

      return this.$i18n.t('status.' + type.toLowerCase() + '.' + status.toLowerCase() + '.color')
    },
  },
}
</script>
